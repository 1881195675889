<template>
  <div
    class="w-100 page_bg_grey blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="sasa-category">
      <banner-section location="shaniv_products-top"/>
      <div class="destop-design">
        <div class="product-view-block mt-15 mobile-hide">
          <h2 class="title fs23 mb-10">
            {{ $t("Products") }}
          </h2>
          <div class="subcategories-part">
            <div class="sastech_company_logo">
              <!-- <a href="/sastech/shaniv/products"><img src="/new-assets/img/shaniv-logo.png" alt="" /></a> -->

              <!--<router-link v-if="company == 'sastech'" :to="{ name: 'Sastech By Company', params: { company: 'shaniv' } }">-->
              <img src="/new-assets/img/shaniv-logo.png" alt="" />
              <!--</router-link>-->
              <!--<router-link v-else-if="company == 'shaniv'" :to="{ name: 'Sastech By Company', params: { company: 'sastech' } }">-->
              <!--<img src="/new-assets/img/2סאסאטק.png" alt="" />-->
              <!--</router-link>-->
            </div>

            <div class="sastechcategories__title-txt">
              <p class="fs20">{{ $t('Minimum order') }}: {{ minOrderAmount }} ₪</p>
            </div>
          </div>
        </div>
        <div class="sastech-content__img filter-block">
          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(27) ? 'active' : ''"
            @click="getProductByTag(27)"
            ref="tag27"
          >
            <div class="sastech__img df-c">
              <img
                src="/new-assets/icon/toilet.png"
                style="width: 100px"
                alt=""
              />
            </div>
            <p>{{ $t('Paper & paper products') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(29) ? 'active' : ''"
            @click="getProductByTag(29)"
            ref="tag29"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/7__97915.png" alt="" />
            </div>
            <p>{{ $t('Complement') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(30) ? 'active' : ''"
            @click="getProductByTag(30)"
            ref="tag30"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/1__10836.png" alt="" />
            </div>
            <p>{{ $t('Disinfection & cleaning') }}</p>
          </div>
          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(31) ? 'active' : ''"
            @click="getProductByTag(31)"
            ref="tag31"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/2__28275.png" alt="" />
            </div>
            <p>{{ $t('Bleach') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(32) ? 'active' : ''"
            @click="getProductByTag(32)"
            ref="tag32"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/3__95670.png" alt="" />
            </div>
            <p>{{ $t('Dish washing') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(33) ? 'active' : ''"
            @click="getProductByTag(33)"
            ref="tag33"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/4__90756.png" alt="" />
            </div>
            <p>{{ $t('Sprays') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(34) ? 'active' : ''"
            @click="getProductByTag(34)"
            ref="tag34"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/5__23400.png" alt="" />
            </div>
            <p>{{ $t('Laundry') }}</p>
          </div>
          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(35) ? 'active' : ''"
            @click="getProductByTag(35)"
            ref="tag35"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/6__68478.png" alt="" />
            </div>
            <p>{{ $t('Floor cleaning') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(36) ? 'active' : ''"
            @click="getProductByTag(36)"
            ref="tag36"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/norture.png" alt="" />
            </div>
            <p>{{ $t('Cultivation') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(37) ? 'active' : ''"
            @click="getProductByTag(37)"
            ref="tag37"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/12__63945.png" alt="" />
            </div>
            <p>{{ $t('One-time') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(38) ? 'active' : ''"
            @click="getProductByTag(38)"
            ref="tag38"
          >
            <div class="sastech__img df-c">
              <img
                src="/new-assets/icon/webp.net-resizeimage__14137.png"
                alt=""
              />
            </div>
            <p>{{ $t('Toilets & bathrooms') }}</p>
          </div>

          <div
            class="sastech-card sastech__img-content pointer"
            :class="selectedTags.includes(28) ? 'active' : ''"
            @click="getProductByTag(28, true)"
            ref="tag28"
          >
            <div class="sastech__img df-c">
              <img src="/new-assets/icon/building.png" alt="" />
            </div>
            <p>{{ $t('Institutional') }}</p>
          </div>

        </div>
        <div class="df justify-content-between mt-10 filter-block">
          <div class="log-filter df">
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`טאצ'`) ? 'active' : ''"
              @click="getProductByManufacture(`טאצ'`)"
              ref="mfטאצ'"
            >
              <img
                src="/new-assets/icon/TOUCH.png"
                alt=""
                style="height: 38px; width: auto"
              />
            </div>
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`גרין קר`) ? 'active' : ''"
              @click="getProductByManufacture(`גרין קר`)"
              ref="mfגרין קר"
            >
              <img src="/new-assets/icon/GC.png" alt="" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`מג'יק`) ? 'active' : ''"
              @click="getProductByManufacture(`מג'יק`)"
              ref="mfמג'יק"
            >
              <img
                src="/new-assets/icon/download.jpg"
                alt=""
                style="width: 40px; height: 40px"
              />
            </div>
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`מאקסול- מקס`) ? 'active' : ''"
              @click="getProductByManufacture(`מאקסול- מקס`)"
              ref="mfמאקסול- מקס"
            >
              <img src="/new-assets/icon/MM.png" alt="" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`LOVELY`) ? 'active' : ''"
              @click="getProductByManufacture(`LOVELY`)"
              ref="mfLOVELY"
            >
              <img src="/new-assets/icon/LOVELY.png" alt="" style="width: 100%" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`ביוטי קלטור`) ? 'active' : ''"
              @click="getProductByManufacture(`ביוטי קלטור`)"
              ref="mfביוטי קלטור"
            >
              <img src="/new-assets/icon/BC.png" alt="" style="width: 100%" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :class="selectedMf.includes(`נובו`) ? 'active' : ''"
              @click="getProductByManufacture(`נובו`)"
              ref="mfנובו"
            >
              <img src="/new-assets/icon/NOVO.png" alt="" style="width: 100%" />
            </div>
          </div>

          <div class="df justify-content-between">
            <div @click="resetFilter()" class="header__filter-btn df-c cursor-pointer">
              <p>
                {{ $t('Apply Filter_FILTERS') }}
              </p>
            </div>
            <router-link
                    :to="{
              name: 'Sastech'
            }"
            >
              <div class="header__filter-btn df-c">
                <img src="/new-assets/icon/badge-percent-light.svg" alt="" />
                <p>{{ $t("Promotions") }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="mobile-design">
        <div class="title-logo_div">
          <h2 class="fs21 color-blue4 text-center w-100 my-5">
            {{ $t("Products") }}
          </h2>
          <img src="/new-assets/img/shaniv-logo.png" alt="" class="company-logo" />
        </div>
        <div class="filter-button-group d-flex justify-content-between mt-10">
          <div class="d-flex">
            <div class="p-relative" ref="mshowSortingToggleButton">
              <div class="grey-btn mx-2" @click="mshowSortingOptionsToggle">
                <img src="/assets/img/icons/sort.png" alt="">
                {{ $t("Sort") }}
              </div>
              <div class="sort-options" v-if="mshow_sorting_options">
                <div 
                  v-for="sort_option of sorting_options" 
                  class="sort-option" 
                  :class="{'text-bold': sorting.key == sort_option.key}" 
                  @click="changeSorting(sort_option)"
                  :key="sort_option.key"
                > 
                  <span>{{ sort_option.name }}</span>
                </div>
              </div>
            </div>

            <div class="grey-btn mx-2" @click="openFilterModal">
              <img src="/assets/img/icons/filter.png" alt="">
              {{ $t("Filter") }}
            </div>
            <div class="grey-btn mx-2 cancelFilter-btn" @click="resetFilter" v-if="filteredSelectedTags.length!=0 || filteredSelectedMf.length!=0">
              <img src="/assets/img/icons/filter.png" alt="">
              {{ $t("Apply Filter") }}
            </div>
          </div>
          <router-link :to="{ name: 'Sastech' }">
            <div class="header__filter-btn d-flex align-items-center">
              <img src="/new-assets/icon/badge-percent-light.svg" alt="" />
              <p>{{ $t("Promotions") }}</p>
            </div>
          </router-link>
        </div>
        <div class="selected-filters d-flex align-items-center flex-wrap mt-10">
          <template v-for="(item, index) in filteredSelectedMf">
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-if="item == `טאצ'`"
            >
              <img
                src="/new-assets/icon/TOUCH.png"
                alt=""
                style="height: 25px; width: auto"
              />
            </div>
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-else-if="item == `גרין קר`"
            >
              <img src="/new-assets/icon/GC.png" alt="" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-else-if="item == `מג'יק`"
            >
              <img src="/new-assets/icon/download.jpg" alt="" style="width: 26px; height: 26px"/>
            </div>
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-else-if="item == `מאקסול- מקס`"
            >
              <img src="/new-assets/icon/MM.png" alt="" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-else-if="item == `LOVELY`"
            >
              <img src="/new-assets/icon/LOVELY.png" alt="" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-else-if="item == `ביוטי קלטור`"
            >
              <img src="/new-assets/icon/BC.png" alt="" />
            </div>
            <div
              class="logo-filter_btn pointer"
              :key="index+'mf'"
              v-else-if="item == `נובו`"
            >
              <img src="/new-assets/icon/NOVO.png" alt="" />
            </div>
          </template>
          <template v-for="(item, index) in filteredSelectedTags">
            <div
              class="grey-btn m-24"
              v-if="item == 27"
              :key="index"
            >
              <p>{{ $t('Paper & paper products') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 28"
              :key="index"
            >
              <p>{{ $t('Institutional') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 29"
              :key="index"
            >
              <p>{{ $t('Complement') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 30"
              :key="index"
            >
              <p>{{ $t('Disinfection & cleaning') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 31"
              :key="index"
            >
              <p>{{ $t('Bleach') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 32"
              :key="index"
            >
              <p>{{ $t('Dish washing') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 33"
              :key="index"
            >
              <p>{{ $t('Sprays') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 34"
              :key="index"
            >
              <p>{{ $t('Laundry') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 35"
              :key="index"
            >
              <p>{{ $t('Floor cleaning') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 36"
              :key="index"
            >
              <p>{{ $t('Cultivation') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 37"
              :key="index"
            >
              <p>{{ $t('One-time') }}</p>
            </div>
            <div
              class="grey-btn m-24"
              v-else-if="item == 38"
              :key="index"
            >
              <p>{{ $t('Toilets & bathrooms') }}</p>
            </div>
          </template>
        </div>
      </div>

      <div class="sub-main__content-header products-content df justify-content-between">
        <div class="mt-10 m-24 df">
          <p class="fs20 weight-700">{{ $t("Search") }}</p>
          <input type="text" class="input-search__text" v-model="searchKey" @input="debounceSearch" />
        </div>
        <div class="header-filter__box df w-unset" @click="showSortingOptionsToggle" ref="showSortingToggleButton">
          <div class="content-header__filter df-c destop-design">
            <div class="filter-arrow"
                :class="{'direction-asc': sorting.direction === 'ASC', 'direction-desc': sorting.direction === 'DESC'}">
            </div>
            <p>{{ sorting.name }}</p>
            <img src="/new-assets/icon/filter.svg" alt="" class="filter-icon" />
            <div class="sort-options" v-if="show_sorting_options">
              <div 
                v-for="sort_option of sorting_options" 
                class="sort-option" 
                :class="{'text-bold': sorting.key == sort_option.key}"
                @click="changeSorting(sort_option)"
                :key="sort_option.key"
              > 
                <span>{{ sort_option.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="product-view-block products-content grid">
        <div v-for="item in products" :key="item.id">
          <div class="sastech-box">
            <div class="content">
              <router-link
                :to="{
                  name: 'SastechProduct',
                  params: { company: item.company, id: item.id },
                }"
              >
                <div class="slider-box__center df">
                  <div class="slider-box__center-img">
                    <img
                      :src="item.image || '/new-assets/img/shaniv-logo.png'"
                      alt=""
                    />
                  </div>
                  <div class="sastech-box__center-content p-relative">
                    <p class="weight-700 limited-lines-number lines-number-2 break-word" :title="item[currentLang + '_name']">{{ item[currentLang + '_name'] }}</p>
                    <p class="limited-lines-number lines-number-3 break-word" :title="item[currentLang + '_description']">{{ item[currentLang + '_description'] }}</p>
                    <p class="sastech-quantity weight-700">{{ $t('Package quantity') }}: {{ getProductPackageQuantity(item) }}</p>
                    <p class="sastech-barcode weight-700 space-nowrap">{{ $t('Barcode') }}: {{ item.BARCODE || '-' }}</p>
                  </div>
                </div>
              </router-link>
              <div class="slider-box__footer d-flex justify-content-between">
                <div class="product-prices">
                  <div v-if="item.DISCOUNT_VATPRICE">
                    <p class="fs14 color-white weight-700">{{ $t('Unit price') }} {{ item.DISCOUNT_VATPRICE }} ₪</p>
                  </div>
                  <div>
                    <div v-if="item.DISCOUNT_VATPRICE" class="footer__box2-txt fs16 mb-2 limited-lines-number lines-number-1 break-word">
                      <p>{{ $t('Package price') }} {{ getProductPackageDiscountPrice(item) }} ₪</p>
                    </div>
                    <div
                      class="footer__box2-btn df align-items-center cursor-pointer"
                      v-on:click="onAddToCart(item)"
                    >
                      <img
                        src="/new-assets/icon/shopping-cart-light.svg"
                        class="mx-5"
                        alt=""
                      />
                      <p>{{ $t('Add basket') }}</p>
                    </div>
                  </div>
                </div>
                <div class="cart-price-block">
                  <p class="weight-700 color-white fs20 mb-7">
                    <i>{{ getTempToCartPrice(item) }} <span class="fs16">₪</span></i>
                  </p>
                  <div class="cart-num">
                    <img
                      src="/new-assets/icon/plus-circle-solid.svg"
                      alt=""
                      class="circle-btn plus-circle"
                      @click="onIncreaseQuantity(item)"
                    />
                    <div class="product-num">
                      <p>{{ getTempQuantity(item) }}</p>
                    </div>
                    <!-- <input type="text" class="product-num" v-model=" getTempQuantity(item) " :disabled="disabled" /> -->
                    <img
                      src="/new-assets/icon/minus-circle-solid.svg"
                      alt=""
                      class="circle-btn minus-circle"
                      @click="onDecreaseQuantity(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button 
        class="primary-btn btn-limit-width load-more_btn" 
        @click="loadMore()" 
        v-if="products.length < totalProducts"
      >
        {{ $t("Show more") }}
      </button>
    </div>
    <!-- ----- cart modal ----------- -->
    <CartModal v-on:closeModal="closeCartModal()" :cartModalData="cartModalData" :isPackageModal="false"></CartModal>
    
    <!-- filter modal -->
    <div class="filter-modal" v-show="isModalVisible">
      <img src="/assets/img/icons/times-light-black.svg" alt="" class="close-btn pointer" @click="closeFilterModal">
      <button 
        v-if="isFilterAvailable != 0"
        class="primary-btn clear-filter-btn" 
        @click="resetFilter()"
      >
        {{ $t("Clear filters") }}
      </button>
      <h2 class="text-center mt-5">{{ $t("Filter") }}</h2>

      <div class="log-filter df justify-content-between mt-10">
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`טאצ'`) ? 'active' : ''"
          @click="addMfToSelected(`טאצ'`)"
          ref="mfטאצ'"
        >
          <img
            src="/new-assets/icon/TOUCH.png"
            alt=""
            style="height: 25px; width: auto"
          />
        </div>
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`גרין קר`) ? 'active' : ''"
          @click="addMfToSelected(`גרין קר`)"
          ref="mfגרין קר"
        >
          <img src="/new-assets/icon/GC.png" alt="" />
        </div>
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`מג'יק`) ? 'active' : ''"
          @click="addMfToSelected(`מג'יק`)"
          ref="mfמג'יק"
        >
          <img
            src="/new-assets/icon/download.jpg"
            alt=""
            style="width: 26px; height: 26px"
          />
        </div>
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`מאקסול- מקס`) ? 'active' : ''"
          @click="addMfToSelected(`מאקסול- מקס`)"
          ref="mfמאקסול- מקס"
        >
          <img src="/new-assets/icon/MM.png" alt="" />
        </div>
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`LOVELY`) ? 'active' : ''"
          @click="addMfToSelected(`LOVELY`)"
          ref="mfLOVELY"
        >
          <img src="/new-assets/icon/LOVELY.png" alt="" style="width: 100%" />
        </div>
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`ביוטי קלטור`) ? 'active' : ''"
          @click="addMfToSelected(`ביוטי קלטור`)"
          ref="mfביוטי קלטור"
        >
          <img src="/new-assets/icon/BC.png" alt="" style="width: 100%" />
        </div>
        <div
          class="logo-filter_btn pointer"
          :class="selectedMf.includes(`נובו`) ? 'active' : ''"
          @click="addMfToSelected(`נובו`)"
          ref="mfנובו"
        >
          <img src="/new-assets/icon/NOVO.png" alt="" style="width: 100%" />
        </div>
      </div>

      <div class="sastech-content__img filter-block mt-7">
        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(27) ? 'active' : ''"
          @click="addTagsToSelected(27)"
          ref="tag27"
        >
          <div class="sastech__img df-c">
            <img
              src="/new-assets/icon/toilet.png"
              style="width: 100px"
              alt=""
            />
          </div>
          <p>{{ $t('Paper & paper products') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(28) ? 'active' : ''"
          @click="addTagsToSelected(28)"
          ref="tag28"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/building.png" alt="" />
          </div>
          <p>{{ $t('Institutional') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(29) ? 'active' : ''"
          @click="addTagsToSelected(29)"
          ref="tag29"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/7__97915.png" alt="" />
          </div>
          <p>{{ $t('Complement') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(30) ? 'active' : ''"
          @click="addTagsToSelected(30)"
          ref="tag30"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/1__10836.png" alt="" />
          </div>
          <p>{{ $t('Disinfection & cleaning') }}</p>
        </div>
        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(31) ? 'active' : ''"
          @click="addTagsToSelected(31)"
          ref="tag31"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/2__28275.png" alt="" />
          </div>
          <p>{{ $t('Bleach') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(32) ? 'active' : ''"
          @click="addTagsToSelected(32)"
          ref="tag32"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/3__95670.png" alt="" />
          </div>
          <p>{{ $t('Dish washing') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(33) ? 'active' : ''"
          @click="addTagsToSelected(33)"
          ref="tag33"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/4__90756.png" alt="" />
          </div>
          <p>{{ $t('Sprays') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(34) ? 'active' : ''"
          @click="addTagsToSelected(34)"
          ref="tag34"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/5__23400.png" alt="" />
          </div>
          <p>{{ $t('Laundry') }}</p>
        </div>
        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(35) ? 'active' : ''"
          @click="addTagsToSelected(35)"
          ref="tag35"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/6__68478.png" alt="" />
          </div>
          <p>{{ $t('Floor cleaning') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(36) ? 'active' : ''"
          @click="addTagsToSelected(36)"
          ref="tag36"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/norture.png" alt="" />
          </div>
          <p>{{ $t('Cultivation') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(37) ? 'active' : ''"
          @click="addTagsToSelected(37)"
          ref="tag37"
        >
          <div class="sastech__img df-c">
            <img src="/new-assets/icon/12__63945.png" alt="" />
          </div>
          <p>{{ $t('One-time') }}</p>
        </div>

        <div
          class="sastech-card sastech__img-content pointer"
          :class="selectedTags.includes(38) ? 'active' : ''"
          @click="addTagsToSelected(38)"
          ref="tag38"
        >
          <div class="sastech__img df-c">
            <img
              src="/new-assets/icon/webp.net-resizeimage__14137.png"
              alt=""
            />
          </div>
          <p>{{ $t('Toilets & bathrooms') }}</p>
        </div>
      </div>

      <div class="bottom-action d-flex align-items-center justify-content-center mt-10">
        <button 
          v-if="isFilterAvailable != 0"
          class="primary-btn mx-2"
          @click="applyFilter" 
        >
          {{ $t('Apply Filter') }}
        </button>
        <button class="primary-btn bg-red9 mx-2" @click="closeFilterModal()">
          {{ $t('Cancel') }}
        </button>
      </div>

    </div>

    <!-- mobile bottom fixed menu -->
    <MobileFiexedBottom></MobileFiexedBottom>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import  CartModal from "../../components/CartModal";
import MobileFiexedBottom from '../../components/cart/MobileFiexedBottom.vue';

import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'SastechByCompany',
  mixins: [CartMixin],
  components: {
    BannerSection,
    Loading,
    CartModal,
    MobileFiexedBottom
  },
  data()  {
    return {
      // nextLabel: "<img class src='/assets/img/chevron.svg' />",
      // prevLabel: "<img class src='/assets/img/chevron.svg' />",
      // itemPerSlide: calculateItemPerSlide(),
      tempCart: {},
      cartModalData: {},
      loading: false,
      selectedTags: [],
      include_with_no_tags: false,
      selectedMf: [],
      filteredSelectedTags:[],
      filteredSelectedMf: [],
      page_num: 0,
      searchKey: "",
      show_sorting_options: false,
      mshow_sorting_options: false,
      sorting: {
        key: "default",
        name: this.$t('Default sorting'),
        direction: "",
        field: "",
      },
      sorting_options: [
        {
          key: "default",
          name: this.$t('Default sorting'),
          direction: "",
          field: ""
        },
        {
          key: "price_low_to_high",
          name: this.$t('Price') + " " + this.$t('low to high'),
          direction: "ASC",
          field: "price"
        },
        {
          key: "price_high_to_low",
          name: this.$t('Price') + " " + this.$t('high to low'),
          direction: "DESC",
          field: "price"
        },
      ],
      isModalVisible: false
    };
  },
  methods: {
    ...mapActions('productShaniv', {
      getProducts: 'getProducts'
    }),
    ...mapActions('cartSystem', {
      addProductsToCart: 'addProductsToCart',
      initCart: 'initCart',
    }),
    ...mapActions('alert', {
      error: 'error',
    }),
    // onResize(event) {
    //   this.itemPerSlide = calculateItemPerSlide();
    // },
    onIncreaseQuantity({ id }) {
      if (typeof this.tempCart[id] === 'undefined') {
          this.tempCart[id] = 1;
      }

      this.tempCart[id]++;

      this.tempCart = Object.assign({}, this.tempCart);
    },
    onDecreaseQuantity({ id }) {
      if (typeof this.tempCart[id] === 'number' && this.tempCart[id] > 1) {
          this.tempCart[id]--;
      }

      this.tempCart = Object.assign({}, this.tempCart);
    },
    getTempQuantity({ id }) {
      return (typeof this.tempCart[id] === 'number') ? this.tempCart[id] : 1;
    },
    clearTempQuantity({ id }) {
        this.tempCart[id] = 1;
        this.tempCart = Object.assign({}, this.tempCart);
    },
    // getTempTotalCount({ id, qtySteps }) {
    //   let tempQuantity = this.getTempQuantity({ id });
    //   qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

    //   return tempQuantity * qtySteps;
    // },
    onAddToCart(product) {
      const quantity = this.getTempQuantity(product);

      if (!quantity) {
        this.error(this.$t('Please select quantity'));
        return;
      }

      this.addProductsToCart({
        product,
        quantity,
        company: product.company,
        type: 'products',
      });
      this.clearTempQuantity(product);
      this.createOpenModal(product, quantity);
    },
    createOpenModal(product, quantity) {
      this.cartModalData = product;
      this.cartModalData.added_quantity = quantity;
      this.cartModalData.added_total_price = this.calculateProductQuantityPrice({
        DISCOUNT_VATPRICE: this.cartModalData.DISCOUNT_VATPRICE,
        quantity: this.cartModalData.added_quantity,
        qtySteps: this.cartModalData.qtySteps
      });
      this.cartModalData.package_price = this.getProductPackagePrice(product);
      this.cartModalData.package_discount_price = this.getProductPackageDiscountPrice(product);
      this.cartModalData.total_price = this.cartTotalDiscountVATPrice;
      this.cartModalData.total_unique_count = this.cartTotalUniqueCount;

      this.cartModalData = JSON.parse(JSON.stringify(this.cartModalData));

      this.openCartModal();
    },
    addTagsToSelected(id, include_empty) {
      this.include_with_no_tags = false;
      if (!this.selectedTags.includes(id)) {
        this.selectedTags = [id];
        if (include_empty) {
          this.include_with_no_tags = true;
        }
      } else {
        this.selectedTags = [];
      }
      // if (!this.selectedTags.includes(id)) {
      //   this.selectedTags.push(id);
      //   // this.$refs['tag'+id].classList.add("active");
      // } else {
      //   this.removeA(this.selectedTags, id);
      //   // this.$refs['tag'+id].classList.remove("active");
      // }
    },
    addMfToSelected(mf) {
      if (!this.selectedMf.includes(mf)) {
        this.selectedMf.push(mf);
        // this.$refs['mf'+mf].classList.add("active");
      } else {
        this.removeA(this.selectedMf, mf);
        // this.$refs['mf'+mf].classList.remove("active");
      }
    },
    getProductByTag(id, include_empty = false) {
      this.addTagsToSelected(id, include_empty)
      this.loadProducts(0);
    },
    getProductByManufacture(mf) {
      this.addMfToSelected(mf);
      this.loadProducts(0);
    },
    resetFilter() {
      this.selectedTags = [];
      this.include_with_no_tags = false;
      this.selectedMf = [];
      this.filteredSelectedTags = [];
      this.filteredSelectedMf = [];

      this.loadProducts(0);
    },
    /* <!-- ------------create modal-------------- --> */
    openCartModal() {
      this.$modal.show('cartModal');
      // $('html').css('overflow', 'hidden');
    },
    closeCartModal() {
      this.$modal.hide('cartModal');
      // $('html').css('overflow', 'auto');
    },

    loadProducts(page) {
      page = +page;
      this.page_num = page;
      let params = {
        active: 1,
        user_prices: 1,
        search_term: this.searchKey,
        page: page,
        per_page: 40,
        sorting_field: this.sorting.field || null,
        sorting_direction: this.sorting.direction || null,
        include_with_no_tags: this.include_with_no_tags || null,
        // company: "sastech"
      };

      if (this.selectedTags) {
        params.tag = this.selectedTags;
      }
      if (this.selectedMf) {
        params.manufacturer = this.selectedMf;
      }

      this.getProducts(params);
    },
    getProductPackageQuantity({ id, qtySteps }) {
      return qtySteps && qtySteps > 0 ? qtySteps : 1;
    },
    getProductPackageDiscountPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackagePrice({ id, qtySteps, DISCOUNT_VATPRICE, VATPRICE }) {
      VATPRICE = VATPRICE >= DISCOUNT_VATPRICE ? VATPRICE : DISCOUNT_VATPRICE;
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getTempToCartPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      let tempQuantity = this.getTempQuantity({ id });

      return this.calculateProductQuantityPrice({ DISCOUNT_VATPRICE: DISCOUNT_VATPRICE, quantity: tempQuantity, qtySteps: qtySteps});
    },
    calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    },
    loadMore() {
      const page_num = this.page_num + 1;
      this.loadProducts(page_num);
    },
    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadProducts(0);
      }, 1000)
    },
    changeSorting(newSort) {
      if (this.sorting.key != newSort.key) {
        this.sorting = newSort;
        this.loadProducts(0);
      }
    },
    showSortingOptionsToggle() {
      this.show_sorting_options = !this.show_sorting_options;
    },
    mshowSortingOptionsToggle() {
      this.mshow_sorting_options = !this.mshow_sorting_options;
    },
    clickHandler(e) {
      if (!this.$refs.showSortingToggleButton.contains(e.target)) {
        this.show_sorting_options = false;
      }
      if (!this.$refs.mshowSortingToggleButton.contains(e.target)) {
        this.mshow_sorting_options = false;
      }
    },
    openFilterModal() {
      this.isModalVisible = true;
    },
    closeFilterModal() {
      this.isModalVisible = false;
    },
    async applyFilter() {
      await this.loadProducts(0);
      this.filteredSelectedTags = JSON.parse(JSON.stringify(this.selectedTags));
      this.filteredSelectedMf = JSON.parse(JSON.stringify(this.selectedMf));
      // this.filteredSelectedTags = this.filteredSelectedTags;
      // this.filteredSelectedMf = this.filteredSelectedMf;
      this.closeFilterModal();
    }
  },
  computed: {
    ...mapState({
      products: (state) => state.productShaniv.products,
      getProductsInProgress: (state) =>
        state.productShaniv.getProductsInProgress,
      cart: (state) => state.cartSystem.cart,
      minOrderAmount: (state) => state.shaniv_customer.minOrderAmount,
      totalProducts: (state) => state.productShaniv.totalProducts,
    }),
    ...mapGetters('cartSystem', {
      productsTotalQuantity: 'productsTotalQuantity',
      productsTotalDiscountPrice: 'productsTotalDiscountPrice',
      productsTotalDiscountVATPrice: 'productsTotalDiscountVATPrice',
      cartTotalQuantity: 'cartTotalQuantity',
      cartTotalDiscountPrice: 'cartTotalDiscountPrice',
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalUniqueCount: 'cartTotalUniqueCount',
      cartTotalDiscountVAT: 'cartTotalDiscountVAT',
      cartTotalDiscountCalcVAT: 'cartTotalDiscountCalcVAT',
    }),
    company() {
      return this.$route.params.company || 'shaniv';
    },
    currentLang() {
      let currentLang = localStorage.getItem('language');
      currentLang = currentLang ? currentLang.toString() : 'he';
      return currentLang;
    },
    isFilterAvailable() {
      return this.selectedMf.length + this.selectedTags.length;
    }
  },
  async created(e) {
    // console.log(this.$route);
    this.initCart();
    // if (this.$route.params?.catalog) {
    this.loadProducts(0);
    // }
    // window.addEventListener('resize', this.onResize);
    window.addEventListener('click', this.clickHandler);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickHandler)
  },
  watch: {
    // $route(to, from) {
    //   this.getProducts({});
    // },
    getProductsInProgress(inProgress) {
      this.loading = inProgress;
    },
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.onResize);
  // },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.sasa-category {
  padding: 0 0 100px;
  max-width: 1600px;
  margin: 0 auto;
  .subcategories-part {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 24px;
    padding-left: 80px;
    img {
      object-fit: contain;
    }
    .sastechcategories__title-txt {
      text-align: left;
    }
  }

  .sastech-box__center-content {
    text-align: unset;
    word-break: break-all;
    padding: 0 5px;
  }

  .sastech-box__center-content p {
    color: #0b193b;
    font-family: unset;
    font-size: 14px;
    margin-right: 20px;

    &:first-child {
      color: #0b193b;
      font-family: unset;
      font-size: 16px;
      margin-top: 11px;
      margin-right: 20px;
      min-height: 46px;
    }
  }

  .sastech-box {
    height: 285px;
    direction: ltr;
    // width: 358px;
    /* margin-left: 16px; */
    > div.content {
      padding: 0 8px;
    }
  }

  .sastechcategories__title-txt p:nth-child(1) {
    font-size: 22px;
    color: #2a3b8e;
    font-family: hebrewbold;
  }

  .sastechcategories__title-txt p:nth-child(2) {
    font-size: 22px;
    color: #ff0000;
    font-family: hebrewbold;
  }
}

.sastech-content__img {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;

  a {
    text-decoration: none;
  }

  margin-top: 16px;
  margin-bottom: 16px;
  // max-width: calc(180px * 4);

  .sastech-card {
    &:first-of-type {
      margin-left: 0px;
    }
    height: 130px;
    border-radius: 20px;
    border-top-left-radius: 38px;
    border-top-right-radius: 38px;
    border: 1px solid #9F9F9F;
    opacity: 0.6;
    &.active {
      border: 2px solid #65BCFF;
      opacity: 1;
      background-color: #22377d;
      p {
        color: #fff;
      }
      .sastech__img {
        border: 2px solid #65BCFF;
        margin-top: -2px;
        margin-right: -2px;
        width: calc(100% + 4px);
      }
    }
  }

  @media screen and (max-width: 1524px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: none;
  }
  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.sastech__img {
  height: 100px;
  border-radius: 25px;
  border: 1px solid #9f9f9f;
  background-color: #ffffff;
  margin-right: -1px;
  margin-top: -1px;
  width: calc(100% + 2px);
  img {
    max-width: 80%;
  }
}

.sastech__img-content p {
  text-align: center;
  font-family: assisbold;
  font-size: 14px;
  color: #002036;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
}

.sastech_company_logo {
  display: flex;
  align-items: center;
  img {
    height: 78px;
  }
}

div.sastech-box {
  img {
    max-width: 100%;
  }
  .slider-box__center {
    justify-content: flex-start;
    height: 165px;
  }
  .slider-box__center-img {
    width: 35%;
    img {
      max-height: 200px;
      min-width: 90px;
      margin: 10px;
      padding: 3px;
    }
  }
  .sastech-quantity {
    position: absolute;
    bottom: 25px;
    right: 0;
  }
  .sastech-barcode {
    position: absolute;
    bottom: 5px;
    right: 0;
  }
  .slider-box__footer {
    background: #74287A;
    padding: 10px;
    height: 120px;
    .cart-price-block {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }
    .product-prices {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .footer__box2-btn {
      height: 36px;
      margin: 0;
      border-radius: 7px;
      font-size: 16px;
      p {
        font-size: 16px;
      }
    }
  }
}

.sub-main__content-header {
  margin-top: 40px;
  .content-header__filter {
    margin: 16px 0;
  }

  margin-bottom: 0px;

  @media screen and (max-width: 1080px) {
    margin-bottom: 120px;
    position: relative;
    .hedear-filter-btnbox {
      position: absolute;
      top: 120px;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 16px;
  margin-top: 8px;
  // .slider-footer__add-btn {
  //   position: absolute;
  //   top: 50%;
  //   left: 8px;
  //   transform: translateY(-50%);
  // }
  // .slider-box__footer {
  //   position: relative;
  //   background: #74287A;

  //   > .slider-footer__box2 {
  //     position: absolute;
  //     right: 0;
  //   }
  // }
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.logo-filter_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #234395;
  background-color: #fff;
  width: 68px;
  height: 42px;
  margin-left: 10px;
  border-radius: 10px;
  opacity: 0.6;
  img {
    width: 80%;
  }
  &.active {
    border: 2px solid #65BCFF;
    border-width: 3px;
    opacity: 1;
  }
}
.footer__box2-title {
  justify-content: unset;
  height: 34px;
}
.footer__box2-txt {
  font-weight: bold;
  font-size: 18px;
  padding-right: 0;
  text-align: unset;
}
.v-btn__content {
  font-size: 20px;
}
.drop-down-list {
  width: 200px;
}
.v-menu__content{
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  width: fit-content !important;
  position: relative !important;
}
.item_in_list{
  cursor: pointer !important;
}
.item_in_list:hover {
  background-color: gray !important;
}
.v-application--wrap{
  min-height: 25px !important;
}

.slider-box__center:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.cart-num {
  position: relative;
  width: 82px;
  display: flex;
  .circle-btn {
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  .plus-circle {
    right: 0px;
  }
  .minus-circle {
    left: 0px;
  }
  .product-num {
    background-color: #fff;
    width: 60px;
    text-align: center;
    margin: 0 12px;
    height: 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content-header__filter {
  position: relative;
  .filter-icon {
    width: 37px;
    height: 37px;
  }
  p {
    font-size: 18px;
  }
  .filter-arrow {
    margin: 0 5px;
    width: 14.35px;
    height: 17.75px;
    position: relative;
    background: url('/new-assets/icon/Group 2226.svg');
  }
  .filter-arrow::after {
    content:"";
    background-image: inherit;
    width: inherit;
    height: 100%;
    position: absolute;
    bottom: 0;
    transform: scaleY(-1)
  }

  .filter-arrow.direction-asc::after,
  .filter-arrow.direction-desc::after {
    content: "";
    display: none;
  }
  .filter-arrow.direction-desc {
    transform: scaleY(-1);
  }

  .sort-options {
    position: absolute;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 999;
    left: 0;
    top: 100%;
  }
  .sort-option {
    padding: 5px 10px;
    border-bottom: 1px solid #d4d4d4;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.products-content {
  max-width: 1450px;
  margin: 0 auto;
}

.filter-block {
  max-width: 1380px;
  margin: 0 auto;
}

.header__filter-btn {
  height: 42px;
  padding: 0 20px;
  width: unset;
  p {
    font-size: 16px;
  }
}

.load-more_btn {
  margin: 20px auto;
}

.input-search__text {
  margin-left: 10px;
}

.ltr-type {
  .sastech-card.active {
    .sastech__img {
      margin-left: -2px;
    }
  }
  .subcategories-part {
    padding-left: 0;
    padding-right: 80px;
  }
  .sastech__img {
    margin-right: unset;
    margin-left: -1px;
  }
}
@media screen and (max-width: 1700px) {
  .sasa-category .subcategories-part {
    padding-left: 0;
    padding-right: 0;
  }
}

/* mobile design start */
.mobile-design {
  display: none;
}
@media (max-width: 480px) {
  .sub-main__content-header {
    margin-top: 0;
  }
  .destop-design {
    display: none;
  }
  .mobile-design {
    display: block;
    position: relative;
    .title-logo_div {
      .company-logo {
        position: absolute;
        top: -5px;
        right: 5px;
        height: 40px;
      }
    }
    .grey-btn {
      display: flex;
      align-items: center;
      padding: 3px 7px;
      border: 1px solid #d4d4d4;
      border-radius: 6px;
      background-color: #fff;
      font-size: 13px;
      cursor: pointer;
      height: 28px;
      img {
        height: 16px;
        margin-left: 3px;
      }
    }
    .filter-button-group {
      .header__filter-btn {
        margin: 0 5px;
        height: auto;
        padding: 5px;
        border-radius: 6px;
        cursor: pointer;
        img {
          height: 17px;
          margin-left: 5px;
        }
        p {
          font-size: 13px;
          margin: 0;
        }
      }
      .cancelFilter-btn {
        border: 1px solid #FF0000;
        background-color: #F0D1D6;
      }
    }
    .selected-filters {
      .logo-filter_btn {
        opacity: 1;
        height: 32px;
        width: 48px;
        border-radius: 7px;
        margin: 5px;
      }
    }
    .sort-options {
      position: absolute;
      background-color: #fff;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      z-index: 999;
      top: 100%;
      right: 7px;
      .sort-option {
        padding: 5px 10px;
        border-bottom: 1px solid #d4d4d4;
        white-space: nowrap;
      }
    }
  }
  .ltr-type {
    .mobile-design {
      .title-logo_div {
        .company-logo {
          left: unset;
          right: 5px;
          height: 38px;
        }
      }
      .filter-button-group {
        .grey-btn {
          img {
            margin-left: unset;
            margin-right: 3px;
          }
        }
        .header__filter-btn {
          img {
            margin-left: unset;
            margin-right: 5px;
          }
        }
      }
      .sort-options {
        right: unset;
        left: 7px;
      }
    }
  }
}

.filter-modal {
  position: fixed;
  top: 60px;
  width: 100vw;
  left: 0;
  right: 0;
  background-color: #EEEEEE;
  height: calc(100vh - 60px);
  padding: 0 10px;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    height: 20px;
    width: 20px;
  }
  .clear-filter-btn {
    position: absolute;
    top: 13px;
    left: 10px;
    width: auto;
    font-size: 13px;
    height: auto;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .logo-filter_btn {
    width: 47px;
    height: 32px;
    margin: 0;
    border-radius: 7px;
  }
  .sastech-content__img {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    .sastech__img {
      height: 80px;
      border-radius: 15px;
      img {
        max-width: 70%;
      }
    }
    .sastech-card {
      height: 112px;
      border-radius: 15px;
    }
    .sastech__img-content p {
      font-size: 12px;
    }
  }
  .bottom-action {
    .primary-btn {
      border-radius: 10px;
      font-size: 16px;
      height: 38px;
      max-width: 160px;
    }
  }
}
</style>